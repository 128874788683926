<template>
    <a-drawer
        placement="bottom"
        :visible="visible"
        class="activity_views"
        height="auto"
        :zIndex="5000"
        :closable="true"
        :afterVisibleChange="afterVisibleChange"
        :destroyOnClose="true"
        :getContainer="getContainer"
        @close="closeDrawer()">
        <menu @click="visible = false">
            <slot />
            <div class="close_activity_views" @click="closeButtonHandler()">
                Закрыть
            </div>
        </menu>
    </a-drawer>
</template>

<script>
export default {
    name:'ActivityDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        vis: {
            type: Boolean,
            default: false
        },
        useVis: {
            type: Boolean,
            default: false
        },
        visibleChange: {
            type: Function,
            default: () => {}
        },
        cDrawer: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        visible: {
            get() {
                return this.value || this.vis
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        getContainer() {
            return () => document.body
        }
    },
    methods: {
        closeButtonHandler() {
            if(this.useVis) {
                this.cDrawer()
            }
        },
        afterVisibleChange(vis) {
            this.$emit('afterVisibleChange', vis)
            this.visibleChange(vis)
        },
        closeDrawer() {
            if(this.useVis) {
                this.cDrawer()
            } else {
                this.visible = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.activity_views{
    menu{
        padding: 0px;
        margin: 0px;
    }
}
</style>

<style lang="scss">
$fullscreen: calc(var(--vh, 1vh) * 100);

.activity_views{
    -webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
    .ant-drawer-wrapper-body,
    .ant-drawer-content{
        overflow: hidden;
        height: 100%;
    }
    &.ant-drawer-bottom{
        &.ant-drawer-open{
            .ant-drawer-content-wrapper{
                box-shadow: none;
            }
        }
    }
    .close_activity_views{
        cursor: pointer;
        width: 100%;
        font-size: 14px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    .ant-drawer-content{
        background: transparent;
    }
    .ant-drawer-header-no-title{
        display: none;
    }
    .ant-drawer-body{
        display: flex;
        flex-direction: column;
        max-height: calc($fullscreen - 40px) !important;

        padding: 20px 15px;
        background: rgb(239, 242, 245);
        border-radius: 8px 8px 0px 0px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;

        menu{
            border-radius: var(--borderRadius);
        }
        li{
            list-style: none;
            &:not(.activity_item){
                & + .activity_item{
                    .link{
                        border-radius: var(--borderRadius) var(--borderRadius) 0px 0px;
                    }
                }
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>